export default {
  route: {
    dashboard: 'Dashboard',
    page401: '401',
    page404: '404'
  },
  navbar: {
    logOut: 'Log Out',
    changePassword: 'Change Password',
    changeEmail: 'Change Email',
    dashboard: 'Dashboard',
    train: 'Training',
    task: 'Task',
    news: 'News',
    usernames: 'Username',
    mobile: 'Mobile',
    mail: 'Email',
    name: 'Name',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',
    oldEmail: 'Old Email',
    newEmail: 'New Email',
    confirmEmail: 'Confirm New Email',
  },
  login: {
    title: 'System Login',
    logIn: 'Log In',
    username: 'Please enter email',
    empname: 'Please enter name',
    SAMAccount: 'Please enter SAM account',
    password: 'Please enter password',
    verificationCode: 'Please enter verification code',
    reName: 'Remember username',
    ldapLogIn: 'LDAP Login',
    verificationCodeError: 'Verification code error',
    verificationMobileError: 'Incorrect mobile phone format',
    verificationMailError: 'Incorrect email format',
    formError: 'Please enter username and password!',
  },
  table: {
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  common: {
    cn: 'Chinese',
    jp: 'Japanese',
    en: 'English',
    pleaseSelect: 'Please select',
    pleaseInput: 'Please input',
    pleaseInputText: 'Please input content',
    actionsConfirm: 'Are you sure you want to cancel the email change? Please ensure the current email is your active work email, otherwise, you might not receive emails.',
    formNotNullNotice: 'This field is required',
    canceledAction: 'Action canceled',
    actionsNotice: 'Action notice',
    restartMbti: 'The time for answering has expired, do you want to retake the quiz?',
  },
  home: {
    trainingProgress: 'Training Progress',
    complete: 'You have completed',
    train: 'training items',
    total: 'Total',
    term: 'items',
    continueLearning: 'Continue learning',
    message: 'Unread messages',
    more: 'More',
    unread: 'Unread',
    myTraining: 'My Training',
    trainingName: 'Training Name',
    status: 'Status',
    deadline: 'Deadline',
    startTrain: 'Start Training',
    myTask: 'My Tasks',
    taskName: 'Task Name',
    type: 'Type',
    startTime: 'Start Date',
    endTime: 'End Date',
    startTask: 'Start Test',
  },
  train: {
    allTrain: 'All Training',
    all: 'All',
    completed: 'Completed',
    uncompleted: 'Uncompleted',
    search: 'Please enter search content',
    courseIntroduction: 'Course Details',
    currentProgress: 'Current Progress',
    catalogue: 'Catalogue',
    next: 'Next Section',
    pre1: 'Previous Section',
    next1: 'Next Section',
    describe: 'Chapter Description',
    pleaseFinishPre: 'Please complete the previous sections first',
    check: 'View',
    downloadCertificate: 'Download Certificate',
    startTraining: 'Start Training',
    Congratulations: 'Congratulations',
    continue: 'Continue',
    rePlay: 'Replay',
    redo: 'Redo',
    retest: 'Retake Test',
    Retrospective: 'Review Training',
    training: 'Complete Training',
    accomplish: 'You have completed {durationTimeDesc} of {title}',
    questionTotal: 'Total {totalNum} questions',
    mustAnswerAllQuestions: 'Please answer all the questions',
    explain: 'This submission contains {totalNum} questions, and you answered {rightNum} correctly. At least {minRightNum} correct answers are required to continue training.',
    explainAll: 'This submission contains {totalNum} questions, and you answered {rightNum} correctly.',
    explainTo: 'This submission contains {totalNum} questions, and you answered {rightNum} correctly. You can redo or continue.',
  },
  task: {
    allTask: 'All Tasks',
    answer: 'Correct Answer',
    taskName: 'Task Name',
    status: 'Status',
    type: 'Type',
    startTime: 'Start Time',
    endTime: 'End Time',
    operation: 'Operation',
    completeTask: 'Start Task',
    startexam: 'Start Exam',
    see: 'View',
    submited: 'Submitted',
    enterTaskName: 'Please enter task name',
    searchType: 'Please select task type',
    searchStatus: 'Please enter status',
    search: 'Search',
    questionnaire: 'Survey',
    knowledge: 'Knowledge Competition',
    test: 'Test',
    choose: 'Please choose',
    submit: 'Submit',
    close: 'Closed',
    resubmit: 'Resubmit',
    unStart: 'Not Started',
    preIndex: 'Previous Question',
    requiredMsg: 'Please complete question {index}',
  },
  news: {
    allNews: 'All News',
    all: 'All',
    unread: 'Unread',
    read: 'Read',
    sender: 'Sender',
    messageSubject: 'Message Subject',
    sendingTime: 'Sending Time',
    remark: 'Mark all as read',
    delete: 'Delete',
    return: 'Return to List',
  }
}
